.container {
  position: relative;
}

.select {
  padding: 8px 32px 8px 12px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  appearance: none;
  color: #333;
  font-weight: 500;
  transition: all 0.2s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

.select:hover {
  border-color: #bdbdbd;
}

.select:focus {
  outline: none;
  border-color: #4A6FFF;
  box-shadow: 0 0 0 2px rgba(74, 111, 255, 0.2);
}
