.insightsWidget {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.insightsWidget:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.insightsTitle {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin: 0 0 20px 0;
}

.insightsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
}

.insightItem {
  display: flex;
  gap: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.insightItem:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.insightIcon {
  font-size: 20px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insightContent {
  flex-grow: 1;
}

.insightTitle {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0 0 5px 0;
}

.insightDescription {
  font-size: 13px;
  color: #666;
  line-height: 1.4;
  margin: 0;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #999;
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .insightsWidget {
    padding: 15px;
  }
}
