.statsWidget {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.statsWidget:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.statsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.statsTitle {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin: 0;
}

.statsValue {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin: 10px 0 15px;
}

.chartContainer {
  margin-top: auto;
  height: 80px;
}

@media (max-width: 768px) {
  .statsWidget {
    padding: 15px;
  }

  .statsValue {
    font-size: 24px;
  }
}
