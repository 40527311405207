.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  margin: 40px auto;
}

.errorIcon {
  font-size: 40px;
  margin-bottom: 16px;
}

.errorTitle {
  color: #e53935;
  font-size: 20px;
  margin: 0 0 12px 0;
}

.errorMessage {
  color: #666;
  margin: 0 0 24px 0;
  line-height: 1.5;
}

.retryButton {
  background-color: #4A6FFF;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.retryButton:hover {
  background-color: #3A5CDB;
}

.retryButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(74, 111, 255, 0.3);
}
