.deviceWidget {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.deviceWidget:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.deviceTitle {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin: 0 0 20px 0;
}

.chartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  color: #999;
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .deviceWidget {
    padding: 15px;
  }
}
