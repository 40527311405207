.tableWidget {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableWidget:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.tableTitle {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin: 0 0 20px 0;
}

.tableContainer {
  overflow-x: auto;
  flex-grow: 1;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
}

.table td {
  padding: 12px 10px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
  color: #333;
}

.table tr:last-child td {
  border-bottom: none;
}

.numberColumn {
  text-align: right;
  white-space: nowrap;
}

.pagePathCell, .sourceCell {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noData {
  text-align: center;
  padding: 20px 0;
  color: #999;
  font-style: italic;
}

@media (max-width: 768px) {
  .tableWidget {
    padding: 15px;
  }
  
  .pagePathCell, .sourceCell {
    max-width: 160px;
  }
}
