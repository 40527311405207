.dashboardContainer {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.dashboardTitle {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.statsRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.metricsRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.detailsRow {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;
}

.leftColumn, .rightColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.noData {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .statsRow,
  .metricsRow {
    grid-template-columns: 1fr;
  }

  .detailsRow {
    grid-template-columns: 1fr;
  }
}
