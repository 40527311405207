.positive, .negative, .neutral {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.positive {
  background-color: rgba(39, 174, 96, 0.1);
  color: #27AE60;
}

.negative {
  background-color: rgba(235, 87, 87, 0.1);
  color: #EB5757;
}

.neutral {
  background-color: rgba(130, 130, 130, 0.1);
  color: #828282;
}

.positiveIcon, .negativeIcon, .neutralIcon {
  margin-right: 4px;
  font-size: 14px;
}

.trendValue {
  font-weight: 600;
}
