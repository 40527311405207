.dashboardPage {
  width: 100%;
  padding: 20px;
  background-color: #f9fafb;
  min-height: calc(100vh - 64px); /* Subtract header height */
}

.notFound {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 40px auto;
  max-width: 400px;
}
