.metricWidget {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
}

.metricWidget:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.metricHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.metricTitle {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin: 0;
}

.metricValueContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.metricValue {
  font-size: 32px;
  font-weight: 600;
  color: #333;
}

.metricDescription {
  font-size: 14px;
  color: #777;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .metricWidget {
    padding: 15px;
  }

  .metricValue {
    font-size: 26px;
  }
}
